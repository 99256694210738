import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Event, EventType, Team} from '../../shared/types/events';
import {HttpClient} from '@angular/common/http';
import {EventTypeCatalogComponent} from '../../components/reference-book/event-type-catalog/event-type-catalog.component';
import {TeamCatalogComponent} from '../../components/reference-book/team-catalog/team-catalog.component';

export enum CuEventPopupResult {
    CANCEL,
    SAVE,
    SAVE_AND_CREATE
}

@Component({
    selector: 'app-cu-event-popup',
    templateUrl: './cu-event-popup.component.html',
    styleUrls: ['./cu-event-popup.component.scss']
})
export class CuEventPopupComponent implements OnInit {

    teamAOpened = false;
    teamBOpened = false;
    eventTypeOpened = false;

    form: FormGroup;

    private teams: Team[] = [];
    private teamsA: Team[] = [];
    private teamsB: Team[] = [];

    private eventTypes: EventType[] = [];
    private events: Event[] = [];

    public loading = true;
    public waiting = false;

    public eventTypeCatalogComponent = EventTypeCatalogComponent;
    public teamCatalogComponent = TeamCatalogComponent;

    public error: string | null = null;

    popupResult = CuEventPopupResult;

    constructor(
        private readonly dialogRef: MatDialogRef<CuEventPopupComponent>,
        private readonly fb: FormBuilder,
        private readonly httpClient: HttpClient,
        @Inject(MAT_DIALOG_DATA) public readonly event: Event
    ) {
        this.form = this.fb.group({
            teamA: [null],
            teamB: [null],
            eventName: [null],
            eventCaption: [null],
            eventType: [null]
        });
    }

    async ngOnInit(): Promise<void> {
        this.loading = true;
        await Promise.all([
            this.loadEventTypes(),
            this.loadTeams()
        ]);
        if (this.event) {
            this.form.patchValue({
                teamA: this.findTeamByUuid(this.event.teamAUuid),
                teamB: this.findTeamByUuid(this.event.teamBUuid),
                eventName: this.event.eventName,
                eventCaption: this.event.eventCaption,
                eventType: this.findEventTypeByUuid(this.event.eventTypeUuid)
            });
        }
        this.loading = false;
    }

    public close(result: CuEventPopupResult, data?: any): void {
        this.dialogRef.close({
            result,
            data
        });
    }

    public getTeams(): any[] {
        return this.teams;
    }

    public getTeamsA(): any[] {
        return this.teamsA;
    }

    public getTeamsB(): any[] {
        return this.teamsB;
    }

    search(value: string) {
        let filter = value.toLowerCase();
        let reply = this.teams.filter(team => team.name.toLowerCase().startsWith(filter));
        if (reply.length === 0) {
            return [{name: 'Не найдено'} as Team];
        } else {
            return reply;
        }
    }

    onKeyA(value: any) {
        this.teamsA = this.search(value?.value);
    }

    onKeyB(value: any) {
        this.teamsB = this.search(value?.value);
    }

    public getEventTypes(): any[] {
        return this.eventTypes;
    }

    public onEventTypeCreate(eventType: EventType): void {
        this.eventTypes.push(eventType);
        this.form.controls.eventType.setValue(eventType);
    }

    public onTeamCreate(team: Team, controlName: string): void {
        this.teams.push(team);
        this.form.controls[controlName].setValue(team);
    }

    private async loadTeams(): Promise<void> {
        try {
            const teams = await this.httpClient.get<Team[]>(`/adminpanelapi/team/all`).toPromise();
            const sorted = teams.sort(function(a, b){
                if (a.name < b.name) { return -1; }
                if (a.name > b.name) { return 1; }
                return 0;
            });
            this.teams = [{name: 'Не выбрано'} as Team, ...sorted];
            this.teamsB = this.teams;
            this.teamsA = this.teams;
        } catch (e) {

        }
    }

    private async loadEventTypes(): Promise<void> {
        try {
            this.eventTypes = await this.httpClient
                .get<EventType[]>(`/adminpanelapi/event-type/all`, {params: {consistType: 'TICKET'}}).toPromise();
        } catch (e) {

        }
    }

    public async save(): Promise<void> {
        try {
            await this._save();
            this.close(CuEventPopupResult.SAVE);
        } catch (e) {

        }
    }

    private async loadEvent(): Promise<void> {
        try {
            this.events = await this.httpClient
                .get<Event[]>(`/adminpanelapi/event/all`, {params: {consistType: 'TICKET'}}).toPromise();
        } catch (e) {

        }
    }

    public async _save(): Promise<Event> {
        const model: any = {
            caption: this.form.value.eventCaption,
            consistType: 'TICKET',
            eventTypeUuid: this.form.value.eventType.uuid,
            name: this.form.value.eventName,
            teamAUuid: this.form.value.teamA ? this.form.value.teamA.uuid : null,
            teamBUuid: this.form.value.teamB ? this.form.value.teamB.uuid : null
        };
        if (this.event) {
            model.uuid = this.event.uuid;
        }
        this.waiting = true;
        this.error = null;
        this.form.disable();
        try {
            if (this.event) {
                return await this.httpClient.put<Event>(`/adminpanelapi/event/update`, model).toPromise();
            } else {
                return await this.httpClient.post<Event>(`/adminpanelapi/event/create`, model).toPromise();
            }
        } catch (e) {
            if (!this.event) {
                if (e.error.message.includes('Мероприятие с таким составом команд уже существует:')) {
                    const event = e.error.message.split(':')[1].trim();
                    if (event) {
                        await this.loadEvent();
                        if (this.events) {
                            const x = this.events.find((element) => element?.uuid === event);
                            if (x) {
                                return x;
                            }
                            else {
                                this.form.enable();
                                this.waiting = false;
                                this.waiting = false;
                                throw e;
                            }
                        } else {
                            this.form.enable();
                            this.waiting = false;
                            this.waiting = false;
                            throw e;
                        }
                    } else {
                        this.form.enable();
                        this.waiting = false;
                        this.waiting = false;
                        throw e;
                    }
                } else {
                    this.error = e.error.message;
                    this.form.enable();
                    this.waiting = false;
                    throw e;
                }
            } else {
                this.error = e.error.message;
                this.form.enable();
                this.waiting = false;
                throw e;
            }
        }
    }


    public async saveAndCreatEventSession(): Promise<void> {
        try {
            const event = await this._save();
            this.close(CuEventPopupResult.SAVE_AND_CREATE, event);
        } catch (e) {

        }
    }

    private findTeamByUuid(uuid: string): Team | null {
        return this.teams.find(team => team.uuid === uuid) || null;
    }

    private findEventTypeByUuid(uuid: string): EventType | null {
        return this.eventTypes.find(eventType => eventType.uuid === uuid) || null;
    }
}
