<div class="popup-wrapper" *ngIf="!loading">
    <div class="popup-header">
        <div class="body-text-1" *ngIf="!event">Создать мероприятие</div>
        <div class="body-text-1" *ngIf="event">Редактировать мероприятие</div>
        <button
                mat-icon-button
                disableRipple
                type="button"
                class="button-icon"
                color="primary"
                (click)="close(popupResult.CANCEL)"
        >
            <mat-icon svgIcon="times"></mat-icon>
        </button>
    </div>
    <div class="popup-body" [formGroup]="form">
        <div class="body-text-2 teams-title">Выберите состав команд, если необходимо:</div>
        <div class="popup-body-row popup-body-row-2">
            <mat-form-field appearance="fill" color="primary" class="input-select"
                            [class.input-select__opened]="teamAOpened">
                <mat-label>Команда А</mat-label>
                <mat-icon svgIcon="dropdown-arrow" matSuffix></mat-icon>
                <mat-select #teamASelect (openedChange)="teamAOpened = $event" disableRipple
                            formControlName="teamA" disableOptionCentering
                            [panelClass]="['dropdown-panel', 'dropdown-panel-advanced', 'dropdown-panel-advanced-fixed-220']">
                    <div appSimplebarScroller>
                        <div *ngIf="search" class="search-container" style="padding:5px;">
                            <mat-form-field class="input-field" color="primary">
                                <mat-label>Поиск</mat-label>
                                <input type="text" matInput id="searchA" (keyup)="onKeyA($event.target)">
                            </mat-form-field>
                        </div>
                        <mat-option *ngFor="let team of getTeamsA()" [value]="team">
                            <div style="display: flex;">
                                <div>{{team.name ? team.name : '-'}}</div>
                                <img *ngIf="team?.logoLink"
                                     style="margin-left: 5px; height: 20px; margin-top: auto;margin-bottom: auto;"
                                     src="{{team?.logoLink}}" alt="">
                            </div>
                        </mat-option>
                    </div>
                    <div class="create-container" *ngIf="teamAOpened">
                        <app-base-catalog
                                [placeholder]="'Добавить команду'"
                                [component]="teamCatalogComponent"
                                (create)="onTeamCreate($event, 'teamA'); teamASelect.close()"></app-base-catalog>
                    </div>
                </mat-select>
            </mat-form-field>


            <mat-form-field appearance="fill" color="primary" class="input-select"
                            [class.input-select__opened]="teamBOpened">
                <mat-label>Команда Б</mat-label>
                <mat-icon svgIcon="dropdown-arrow" matSuffix></mat-icon>
                <mat-select #teamBSelect (openedChange)="teamBOpened = $event" disableRipple
                            formControlName="teamB" disableOptionCentering
                            [panelClass]="['dropdown-panel', 'dropdown-panel-advanced', 'dropdown-panel-advanced-fixed-220']">
                    <div appSimplebarScroller>
                        <div *ngIf="search" class="search-container" style="padding:5px;">
                            <mat-form-field class="input-field" color="primary">
                                <mat-label>Поиск</mat-label>
                                <input type="text" matInput id="searchB" (keyup)="onKeyB($event.target)">
                            </mat-form-field>
                        </div>
                        <mat-option *ngFor="let team of getTeamsB()" [value]="team">
                            <div style="display: flex;">
                                <div>{{team.name ? team.name : '-'}}</div>
                                <img *ngIf="team?.logoLink"
                                     style="margin-left: 5px; height: 20px; margin-top: auto;margin-bottom: auto;"
                                     src="{{team?.logoLink}}" alt="">
                            </div>
                        </mat-option>
                    </div>
                    <div class="create-container" *ngIf="teamBOpened">
                        <app-base-catalog
                                [placeholder]="'Добавить команду'"
                                [component]="teamCatalogComponent"
                                (create)="onTeamCreate($event, 'teamB'); teamBSelect.close()"></app-base-catalog>
                    </div>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="popup-body-row">
            <mat-form-field class="input-field" color="primary">
                <mat-label>Название мероприятия*</mat-label>
                <input type="text" autocomplete="off" matInput formControlName="eventName">
            </mat-form-field>
        </div>
        <div class="popup-body-row popup-body-row-with-hint">
            <mat-form-field class="input-field" color="primary">
                <mat-label>Подпись мероприятия*</mat-label>
                <input type="text" autocomplete="off" matInput formControlName="eventCaption">
                <mat-hint>Отображается в кассе, на сайте и т.п.</mat-hint>
            </mat-form-field>
        </div>
        <div class="popup-body-row">
            <mat-form-field appearance="fill" color="primary" class="input-select"
                            [class.input-select__opened]="eventTypeOpened">
                <mat-label>Тип мероприятия*</mat-label>
                <mat-icon svgIcon="dropdown-arrow" matSuffix></mat-icon>
                <mat-select #eventTypeSelect (openedChange)="eventTypeOpened = $event" disableRipple
                            formControlName="eventType" disableOptionCentering
                            [panelClass]="['dropdown-panel', 'dropdown-panel-advanced']">
                    <div appSimplebarScroller>
                        <mat-option *ngFor="let eventType of getEventTypes()" [value]="eventType">
                            {{eventType.name}}
                        </mat-option>
                    </div>
                    <div class="create-container" *ngIf="eventTypeOpened">
                        <app-base-catalog
                                [placeholder]="'Добавить новый тип мероприятия'"
                                [component]="eventTypeCatalogComponent"
                                (create)="onEventTypeCreate($event); eventTypeSelect.close()"></app-base-catalog>
                    </div>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="error-container" *ngIf="error">
        <inline-alert-component [text]="error"></inline-alert-component>
    </div>
    <div class="popup-footer">
        <button
                mat-raised-button
                disableRipple
                type="button"
                class="button-primary-filled button-primary-filled-secondary"
                color="primary"
                [disabled]="form.invalid || waiting"
                (click)="save()"
        >
            Сохранить
        </button>
        <button
                mat-stroked-button
                disableRipple
                type="button"
                class="button-primary-outline"
                color="primary"
                [disabled]="form.invalid || waiting"
                (click)="saveAndCreatEventSession()"
        >
            Сохранить и добавить событие
        </button>
    </div>
</div>

<div class="popup-wrapper" *ngIf="loading">
    <div class="popup-header">
        <div class="body-text-1">
            <skeleton-text [inverseColor]="true" [width]="192" [height]="18"></skeleton-text>
        </div>
    </div>
    <div class="popup-body">
        <div class="body-text-2 teams-title">
            <skeleton-text [width]="250" [height]="18"></skeleton-text>
        </div>
        <div class="popup-body-row popup-body-row-2">
            <skeleton-text [width]="220" [height]="44"></skeleton-text>
            <skeleton-text [width]="220" [height]="44"></skeleton-text>
        </div>
        <div class="popup-body-row">
            <skeleton-text [height]="44"></skeleton-text>
        </div>
        <div class="popup-body-row popup-body-row-with-hint">
            <skeleton-text [height]="44"></skeleton-text>
            <skeleton-text [width]="192" [height]="14"></skeleton-text>
        </div>
        <div class="popup-body-row">
            <skeleton-text [height]="44"></skeleton-text>
        </div>
    </div>
    <div class="popup-footer">
        <skeleton-text [height]="36"></skeleton-text>
        <skeleton-text [height]="36"></skeleton-text>
    </div>
</div>

