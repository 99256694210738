import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';

export enum SortState {
    ASC = 'ASC',
    DESC = 'DESC',
    DEFAULT = 'default'
}

@Component({
    selector: 'sort',
    templateUrl: './sort.component.html',
    styleUrls: ['./sort.component.scss']
})
export class SortComponent {

    private static statesTransition: SortState[] = [SortState.ASC, SortState.DESC, SortState.DEFAULT];

    @Input()
    label: string = '';

    @Input()
    state: SortState = SortState.DEFAULT;

    @Output()
    onChange: EventEmitter<SortState> = new EventEmitter<SortState>();

    @Input()
    sortable: boolean = true;

    constructor() {
    }

    @HostListener('click')
    onClick(): void {
        if (!this.sortable) {
            return;
        }
        this.toggleState();
        this.onChange.emit(this.state);
    }

    private toggleState() {
        const index = Math.max(0, SortComponent.statesTransition.indexOf(this.state));
        const nextStateIndex = (index + 1) % 3;
        this.state = SortComponent.statesTransition[nextStateIndex];
    }
}
