<div class="popup-wrapper">
    <div class="popup-header">
        <div class="body-text-1">Предпросмотр</div>
        <button
            mat-icon-button
            disableRipple
            type="button"
            class="button-icon"
            color="primary"
            (click)="cancel()"
        >
            <mat-icon svgIcon="times"></mat-icon>
        </button>
    </div>
    <div class="popup-body" [formGroup]="form">
        <div *ngFor="let field of getControls()" class="popup-body-row" [formGroup]="field">
            <mat-form-field class="input-field" color="primary">
                <mat-label>{{properties[field.controls.field.value]}}</mat-label>
                <input type="text" autocomplete="off" matInput formControlName="value">
            </mat-form-field>
        </div>

    </div>
    <div class="popup-footer">
        <button
            mat-raised-button
            disableRipple
            type="button"
            class="button-primary-filled button-primary-filled-secondary"
            color="primary"
            [disabled]="form.invalid || form.disabled"
            (click)="next()"
        >
            Предпросмотр
        </button>
    </div>
</div>

