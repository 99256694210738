export interface DeviceModel {
    deviceType: DeviceType;
    name: string;
    speed: number;
    uuid: string;
}

export enum DeviceType {
    FISCAL_PRINTER = 'FISCAL_PRINTER',
    TICKET_PRINTER = 'TICKET_PRINTER',
    GRAFIC_PRINTER = 'GRAFIC_PRINTER',
    RECEIPT_PRINTER = 'RECEIPT_PRINTER',
    RFID_READER = 'RFID_READER',
    POS_TERMINAL = 'POS_TERMINAL',
    IMAGE_SCANNER = 'IMAGE_SCANNER',
    FACE_ID = 'FACE_ID',
    CARD_PRINTER = 'CARD_PRINTER',
    PDF = 'PDF',
}
