import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {HttpClient} from '@angular/common/http';
import {formatDate} from '../../shared/utils/date-utils';

@Component({
    selector: 'app-seat-sales-history-popup',
    templateUrl: './seat-sales-history-popup.component.html',
    styleUrls: ['./seat-sales-history-popup.component.scss']
})
export class SeatSalesHistoryPopupComponent implements OnInit {

    public waiting = false;
    public loading = false;

    public error: string | null = null;
    public clientsOpened = false;
    public table: any;

    payMeanNameMap: Map<string, string> = new Map<string, string>([
        ['CASH', 'Наличный рассчёт'],
        ['CARD', 'Банковская карта'],
        ['CASHLESS', 'Оплата по счету'],
        ['CERT', 'Сертификат'],
        ['LOYALITY', 'Программа лояльности'],
        ['ADMIN', 'Администратор'],
        ['EMONEY', 'Электронные деньги']
    ]);

    orderStatusNameMap: Map<string, string> = new Map<string, string>([
        ['CREATED', 'Создан'],
        ['ISSUED', 'Выдан'],
        ['RETURNED', 'Возвращен'],
        ['CANCELED', 'Отменен'],
        ['BLOCKED', 'Блокирован'],
        ['PART_ISSUED', 'Частично выдан']
    ]);

    payStatusNameMap: Map<string, string> = new Map<string, string>([
        ['PAID', 'Оплачен'],
        ['UNPAID', 'Не оплачен'],
        ['PART_PAID', 'Частично оплачен']
    ]);

    receiptTypeNameMap: Map<string, string> = new Map<string, string>([
        ['PAYMENT', 'Продажа'],
        ['REFUND', 'Возврат'],
        ['REFUND_AWAY_SHIFT', 'Возврат']
    ]);

    ticketTypeNameMap: Map<string, string> = new Map<string, string>([
        ['ACTIVE', 'Активный'],
        ['BLOCKED', 'Блокирован'],
        ['REPRINTED', 'Перепечатан'],
        ['RETURNED', 'Возвращен']
    ]);

    constructor(
        private readonly dialogRef: MatDialogRef<SeatSalesHistoryPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: any,
        private readonly httpClient: HttpClient,
    ) {
        
    }

    async ngOnInit(): Promise<void> {
        this.loading = true;
        await this.loadhistory();
        this.loading = false;
    }

    public close(): void {
        this.dialogRef.close();
    }

    public async loadhistory() {
        this.table = await this.httpClient.get(`/adminpanelapi/report/3f77df96-6ca1-4581-a53f-e4819c906193/generate?seatuuid=${this.data.ticket.locationSchemeSeatUuid}&sessionuuid=${this.data.eventSessionUuid}`).toPromise();
    }

    public formatDateforDate(date: string) {
        if (date) {
        return formatDate(date, 'd MMM yyyy HH:mm');
        } else {
            return '';
        }
    }

    public paymeanName(paymean: string) {
        if (paymean) {
            return this.payMeanNameMap.get(paymean);
        } else {
            return '';
        }
    }

    public orderStatusName(var1: string) {
        if (var1) {
            return this.orderStatusNameMap.get(var1);
        } else {
            return '';
        }
    }

    public payStatusName(var1: string) {
        if (var1) {
            return this.payStatusNameMap.get(var1);
        } else {
            return '';
        }
    }

    public receiptTypeName(var1: string) {
        if (var1) {
            return this.receiptTypeNameMap.get(var1);
        } else {
            return '';
        }
    }

    public ticketTypeName(var1: string) {
        if (var1) {
            return this.ticketTypeNameMap.get(var1);
        } else {
            return '';
        }
    }
}
