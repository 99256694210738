import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output, Pipe, PipeTransform,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren
} from "@angular/core";
import {MatMenuTrigger} from "@angular/material/menu";
import {MatCheckbox} from "@angular/material/checkbox";
import {FormControl} from '@angular/forms';

export interface FilterDataOption {
    name: string;
    [key: string]: any;
}

export class FilterDataSource {
    public options: FilterDataOption[] = [];
    public selected: FilterDataOption[] = [];

    constructor(options: FilterDataOption[], selected: FilterDataOption[] = []) {
        this.options = options;
        this.selected = selected;
    }

    public reset(): FilterDataSource {
        return new FilterDataSource(this.options);
    }
}

@Pipe({
    name: 'filterControlSearch',
    pure: false
})
export class FilterControlSearchPipe implements PipeTransform {
    transform(items: FilterDataOption[], query: string): any {
        if (!items || !query) {
            return items;
        }
        const q = query.toLowerCase();
        return items.filter(item => item.name.toLowerCase().indexOf(q) !== -1);
    }
}


@Component({
    selector: 'filter-component',
    template: `
        <button
                mat-stroked-button
                disableRipple
                [matMenuTriggerRestoreFocus]="false"
                class="filter-button"
                [matMenuTriggerFor]="menu"
                [class.filter-button-opened]="!!trigger && trigger.menuOpen"
                [class.filter-button-selected]="dataSource.selected.length > 0"
        >
            <span class="filter-button-label">
                <ng-content select="[filter-placeholder]"></ng-content>
                <span *ngIf="dataSource.selected.length > 0">: {{dataSource.selected.length}}</span>
            </span>
            <mat-icon svgIcon="times" *ngIf="dataSource.selected.length > 0" (click)="$event.stopPropagation(); reset()"></mat-icon>
            <mat-icon svgIcon="filter-arrow"></mat-icon>
        </button>
        <mat-menu #menu="matMenu" class="filter-panel"
                  (close)="onClose()">
            <div (click)="$event.stopPropagation()">
                <div class="filter-popup-header" *ngIf="confirmationRequired">
                    <span class="filter-popup-header-title">
                        <ng-content select="[filter-title]"></ng-content>
                    </span>
                    <button
                            mat-icon-button
                            disableRipple
                            type="button"
                            class="button-icon"
                            color="primary"
                            (click)="close()"
                    >
                        <mat-icon svgIcon="times"></mat-icon>
                    </button>
                </div>
                <div class="search-container" *ngIf="searchable">
                    <mat-form-field floatLabel="never" color="primary" class="input-search">
                        <mat-label>Поиск</mat-label>
                        <mat-icon matPrefix svgIcon="search"></mat-icon>
                        <input matInput autocomplete="off" type="text" [formControl]="searchControl">
                    </mat-form-field>
                </div>
                <div class="filter-popup-body">
                    <mat-list appSimplebarScroller>
                        <mat-list-item *ngFor="let option of dataSource.options | filterControlSearch:searchControl.value" (click)="toggleOption(filterOption, option)">
                            <mat-checkbox #filterOption disableRipple (click)="$event.preventDefault()" [checked]="dataSource.selected.includes(option)"
                                          class="input-checkbox">{{option.name}}
                            </mat-checkbox>
                            <img *ngIf="option?.logoLink" style="height: 24px;margin-left: 8px;" [src]="option?.logoLink">
                        </mat-list-item>
                    </mat-list>
                </div>
                <div class="filter-popup-footer" *ngIf="confirmationRequired">
                    <button
                            mat-button
                            disableRipple
                            type="button"
                            class="button-primary-basic-text"
                            color="primary"
                            (click)="reset()"
                    >
                        Сбросить
                    </button>
                    <button
                            mat-raised-button
                            disableRipple
                            type="button"
                            class="button-primary-filled button-primary-filled-secondary"
                            color="primary"
                            (click)="confirm()"
                    >
                        Применить
                    </button>
                </div>
            </div>
        </mat-menu>
    `
})
export class FilterComponent implements OnChanges {
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger | undefined;
    @ViewChildren('filterOption') filterOptions: QueryList<MatCheckbox> | undefined;

    @Input()
    confirmationRequired: boolean = true;

    @Input()
    dataSource: FilterDataSource = new FilterDataSource([]);

    @Input()
    searchable = false;

    @Output()
    onSelectionChange: EventEmitter<FilterDataOption[]> = new EventEmitter<FilterDataOption[]>();

    public searchControl = new FormControl('');

    private changes: Map<FilterDataOption, MatCheckbox> = new Map<FilterDataOption, MatCheckbox>();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.dataSource) {
            this.changes.clear();
        }
    }

    toggleOption(matCheckbox: MatCheckbox, option: any) {
        matCheckbox.toggle();

        if (!this.changes.has(option)) {
            this.changes.set(option, matCheckbox);
        }

        if (!this.confirmationRequired) {
            this.applyChanges();
            this.onSelectionChange.emit(this.dataSource.selected);
            this.changes.clear();
        }
    }

    confirm() {
        this.applyChanges();
        this.onSelectionChange.emit(this.dataSource.selected);
        this.close();
    }

    reset() {
        this.filterOptions?.toArray().forEach(filterOption => filterOption.checked = false);
        this.dataSource.selected.length = 0;
        this.onSelectionChange.emit(this.dataSource.selected);
        this.close();
    }

    close() {
        this.trigger?.closeMenu();
    }

    onClose() {
        this.revertChanges();
        this.searchControl.setValue('');
    }

    private applyChanges() {
        this.changes.forEach((checkbox: MatCheckbox, option: FilterDataOption) => {
            const index = this.dataSource.selected.indexOf(option);
            if (checkbox.checked) {
                if (index === -1) {
                    this.dataSource.selected.push(option);
                }
            } else {
                if (index > -1) {
                    this.dataSource.selected.splice(index, 1);
                }
            }
        });
    }

    private revertChanges() {
        this.changes.forEach((checkbox: MatCheckbox, option: FilterDataOption) => {
            const index = this.dataSource.selected.indexOf(option);
            checkbox.checked = index > -1;
        });
        this.changes.clear();
    }
}

