import {Component, OnDestroy} from '@angular/core';
import {FilterDataOption, FilterDataSource} from '../../../../shared/ui-kit/filter/filter.component';
import {SortState} from '../../../../shared/ui-kit/sort/sort.component';
import {EventSession, EventSessionType, EventType, Season} from '../../../shared/types/events';
import {HttpClient} from '@angular/common/http';
import {EventSessionSettingsService, SettingsLoadingStatus, SettingsPopupState} from '../event-session-settings.service';
import {Subject, Subscription} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {FullscreenLoaderService} from '../../../../shared/ui-kit/fullscreen-loader/fullscreen-loader.service';
import {formatDate} from '../../../shared/utils/date-utils';
import {parse} from 'date-fns';

interface SourceFilter {
    seasons: Season[];
    eventTypes: EventType[];
    eventSessionTypes: EventSessionType[];
}

interface DestEvent {
    dateBegin: string;
    eventCaption: string;
    eventName: string;
    seasonName: string;
    uuid: string;
    selected: boolean;
    day: string;
    time: string;
}

const convertEventSession = (eventSession: EventSession): EventSession => {
    eventSession.time = formatDate(eventSession.dateBegin, 'HH:mm');
    eventSession.day = formatDate(eventSession.dateBegin, 'dd MMM yyyy');
    eventSession.datetime = parse(eventSession.dateBegin, `yyyy-MM-dd'T'HH:mm:ss`, new Date()).getTime();
    return eventSession;
};

@Component({
    selector: 'app-season-ticket-consist',
    templateUrl: './season-ticket-consist.component.html',
    styleUrls: ['./season-ticket-consist.component.scss']
})
export class SeasonTicketConsistComponent implements OnDestroy {
    sourceTable: EventSession[] = [];
    sourceTableData: EventSession[] = [];
    sourceTableColumns: string[] = ['select-row', 'name', 'date-time', 'event-type', 'event-session-type', 'match-day', 'teamA', 'teamB'];

    destTable: DestEvent[] = [];
    destTableData: DestEvent[] = [];
    destTableColumns: string[] = ['select-row', 'name', 'total'];

    seasonsFilter: FilterDataSource = new FilterDataSource([]);
    eventTypeFilter: FilterDataSource = new FilterDataSource([]);
    eventSessionTypeFilter: FilterDataSource = new FilterDataSource([]);

    sourceFilter: SourceFilter = {
        seasons: [],
        eventTypes: [],
        eventSessionTypes: []
    };
    sourceFilterSubject: Subject<SourceFilter> = new Subject<SourceFilter>();
    sourceFilterSubscription: Subscription;

    private sourceSort: {
        field: string,
        direction: SortState
    } = {field: '', direction: SortState.DEFAULT};

    private destSort: {
        field: string,
        direction: SortState
    } = {field: '', direction: SortState.DEFAULT};

    private eventSessionLoadingSubscription: Subscription;

    public error: string | null = null;

    public sourceTableLoading = true;
    public destTableLoading = true;
    public loading = true;

    constructor(
        private readonly httpClient: HttpClient,
        private readonly eventSessionSettingsService: EventSessionSettingsService,
        private readonly fullscreenLoaderService: FullscreenLoaderService
    ) {
        this.sourceFilterSubscription = this.sourceFilterSubject.pipe(
            switchMap(eventFilter => {
                this.sourceTableLoading = true;
                const params: any = {
                    eventConsistType: 'TICKET',
                    eventSessionTypeUuids: eventFilter.eventSessionTypes?.map(eventSessionType => eventSessionType.uuid) || [],
                    eventTypeUuids: eventFilter.eventTypes?.map(eventType => eventType.uuid) || [],
                    seasonUuids: eventFilter.seasons?.map(season => season.uuid) || [],
                    locationSchemeUuids: this.eventSessionSettingsService.getEventSession()?.locationSchemeUuid
                };
                return this.httpClient.get<EventSession[]>(`/adminpanelapi/event-session/all`, {params});
            })
        ).subscribe((eventSessions) => {
            this.sourceTableLoading = false;

            this.sourceTable = eventSessions.map(convertEventSession);
            this.setSourcePageData();
        });

        this.eventSessionLoadingSubscription = this.eventSessionSettingsService.loading.subscribe(async (status) => {
            if (status === SettingsLoadingStatus.LOADED) {
                this.loadFilters();
                await this.loadDestEvents();
                this.sourceFilterSubject.next(this.sourceFilter);
            }
        });
    }

    ngOnDestroy(): void {
        this.eventSessionLoadingSubscription.unsubscribe();
        this.sourceFilterSubscription.unsubscribe();
    }

    public onSourceSortChange(field: string, direction: SortState): void {
        this.sourceSort = {field, direction};
        this.setSourcePageData();
    }

    public getSourceSortDirection(field: string): SortState {
        if (this.sourceSort.field === field) {
            return this.sourceSort.direction;
        }
        return SortState.DEFAULT;
    }

    public onDestSortChange(field: string, direction: SortState): void {
        this.destSort = {field, direction};
        this.setDestPageData();
    }

    public getDestSortDirection(field: string): SortState {
        if (this.destSort.field === field) {
            return this.destSort.direction;
        }
        return SortState.DEFAULT;
    }

    private async loadFilters(): Promise<void> {
        this.loading = true;
        await Promise.all([
            this.loadSeasons(),
            this.loadEventTypes(),
            this.loadEventSessionTypes()
        ]);
        this.loading = false;
    }

    private async loadSeasons(): Promise<void> {
        try {
            const seasons = await this.httpClient.get<Season[]>(`/adminpanelapi/season/all`).toPromise();
            const sorted = seasons.sort(function(a, b){
                if (a.name < b.name) { return -1; }
                if (a.name > b.name) { return 1; }
                return 0;
            });
            this.seasonsFilter = new FilterDataSource(sorted);
        } catch (e) {

        }
    }

    private async loadEventSessionTypes(): Promise<void> {
        try {
            const eventSessionTypes = await this.httpClient
                .get<EventSessionType[]>(`/adminpanelapi/event-session-type/all`, {params: {consistType: 'TICKET'}}).toPromise();
            const sorted = eventSessionTypes.sort(function(a, b){
                if (a.name < b.name) { return -1; }
                if (a.name > b.name) { return 1; }
                return 0;
            });
            this.eventSessionTypeFilter = new FilterDataSource(sorted);
        } catch (e) {

        }
    }

    private async loadEventTypes(): Promise<void> {
        try {
            const eventTypes = await this.httpClient
                .get<EventType[]>(`/adminpanelapi/event-type/all`, {params: {consistType: 'TICKET'}}).toPromise();
            const sorted = eventTypes.sort(function(a, b){
                if (a.name < b.name) { return -1; }
                if (a.name > b.name) { return 1; }
                return 0;
            });
            this.eventTypeFilter = new FilterDataSource(sorted);
        } catch (e) {

        }
    }

    private async loadDestEvents(): Promise<void> {
        this.destTableLoading = true;
        try {
            const destTable = await this.httpClient
                .get<DestEvent[]>(`/adminpanelapi/event-session/one/by-id/${this.eventSessionSettingsService.getEventSessionUuid()}/season-ticket-contents`).toPromise();

            this.destTable = destTable.map(destEvent => {
                return {
                    ...destEvent,
                    time: formatDate(destEvent.dateBegin, 'HH:mm'),
                    day: formatDate(destEvent.dateBegin, 'dd MMM yyyy')
                };
            });

            this.setSourcePageData();
            this.setDestPageData();
        } catch (e) {

        }
        this.destTableLoading = false;
    }

    public onSeasonChange(seasons: FilterDataOption[]): void {
        this.sourceFilter.seasons = seasons as Season[];
        this.sourceFilterSubject.next(this.sourceFilter);
    }

    public onEventTypeChange(eventTypes: FilterDataOption[]): void {
        this.sourceFilter.eventTypes = eventTypes as EventType[];
        this.sourceFilterSubject.next(this.sourceFilter);
    }

    public onEventSessionTypeChange(eventSessionTypes: FilterDataOption[]): void {
        this.sourceFilter.eventSessionTypes = eventSessionTypes as EventSessionType[];
        this.sourceFilterSubject.next(this.sourceFilter);
    }

    private setSourcePageData(): void {
        const destEventUuids = this.destTable.map(destEvent => destEvent.uuid);

        const eventsCopy = this.sourceTable.filter(sourceEvent => !destEventUuids.includes(sourceEvent.uuid));

        if (this.sourceSort.direction !== SortState.DEFAULT) {
            eventsCopy.sort((a, b): number => {
                let compareResult = 0;
                const left = (a as any)[this.sourceSort.field];
                const right = (b as any)[this.sourceSort.field];
                if (left === null || left === undefined) {
                    compareResult = 1;
                } else if (right === null || right === undefined) {
                    compareResult = -1;
                } else {
                    if (left > right) {
                        compareResult = 1;
                    } else {
                        compareResult = -1;
                    }
                }
                if (this.sourceSort.direction === SortState.DESC) {
                    compareResult *= -1;
                }
                return compareResult;
            });
        }
        this.sourceTableData = eventsCopy;
    }

    private setDestPageData(): void {
        const eventsCopy = this.destTable.slice();

        if (this.destSort.direction !== SortState.DEFAULT) {
            eventsCopy.sort((a, b): number => {
                let compareResult = 0;
                const left = (a as any)[this.destSort.field];
                const right = (b as any)[this.destSort.field];
                if (left === null || left === undefined) {
                    compareResult = 1;
                } else if (right === null || right === undefined) {
                    compareResult = -1;
                } else {
                    if (left > right) {
                        compareResult = 1;
                    } else {
                        compareResult = -1;
                    }
                }
                if (this.destSort.direction === SortState.DESC) {
                    compareResult *= -1;
                }
                return compareResult;
            });
        }
        this.destTableData = eventsCopy;
    }


    public resetFilters(): void {
        this.sourceFilter = {
            seasons: [],
            eventTypes: [],
            eventSessionTypes: []
        };
        this.seasonsFilter = new FilterDataSource(this.seasonsFilter.options);
        this.eventTypeFilter = new FilterDataSource(this.eventTypeFilter.options);
        this.eventSessionTypeFilter = new FilterDataSource(this.eventSessionTypeFilter.options);
        this.sourceFilterSubject.next(this.sourceFilter);
    }

    public async removeDestEvent(destEvent: DestEvent): Promise<void> {

        this.fullscreenLoaderService.open();

        this.error = null;

        try {
            const model = {
                seasonTicketEventSessionUuids: [
                    this.eventSessionSettingsService.getEventSessionUuid()
                ],
                oneOffEventSessionUuids: [destEvent.uuid]
            };
            await this.httpClient
                .request('delete', `/adminpanelapi/event-session-contents/remove`, {body: model}).toPromise();

            this.destTable.splice(this.destTable.indexOf(destEvent), 1);
            this.setDestPageData();
            this.sourceFilterSubject.next(this.sourceFilter);
        } catch (e) {
            this.error = e.error.message;
        }

        this.fullscreenLoaderService.close();
    }

    public async moveToDestTable(): Promise<void> {
        const selectedSourceEvents = this.sourceTable
            .filter(destEvent => destEvent.selected);

        if (selectedSourceEvents.length === 0) {
            return;
        }

        this.error = null;

        this.fullscreenLoaderService.open();

        try {
            const model = {
                oneOffEventSessionUuids: selectedSourceEvents
                    .map(destEvent => destEvent.uuid),
                seasonTicketEventSessionUuids: [
                    this.eventSessionSettingsService.getEventSessionUuid()
                ]
            };
            await this.httpClient.put(`/adminpanelapi/event-session-contents/add`, model).toPromise();

            selectedSourceEvents
                .forEach(sourceEvent => {
                    this.destTable.push({
                        selected: false,
                        uuid: sourceEvent.uuid,
                        dateBegin: sourceEvent.dateBegin,
                        eventCaption: sourceEvent.eventCaption,
                        eventName: sourceEvent.eventName,
                        seasonName: sourceEvent.seasonName,
                        time: formatDate(sourceEvent.dateBegin, 'HH:mm'),
                        day: formatDate(sourceEvent.dateBegin, 'dd MMM yyyy')
                    });
                    this.sourceTable.splice(this.sourceTable.indexOf(sourceEvent), 1);
                });

            this.setDestPageData();
            this.setSourcePageData();
        } catch (e) {
            this.error = e.error.message;
        }

        this.fullscreenLoaderService.close();
    }

    public async moveToSourceTable(): Promise<void> {
        const selectedDestEvents = this.destTableData
            .filter(destEvent => destEvent.selected);

        if (selectedDestEvents.length === 0) {
            return;
        }

        this.error = null;

        this.fullscreenLoaderService.open();

        try {
            const model = {
                oneOffEventSessionUuids: selectedDestEvents
                    .map(destEvent => destEvent.uuid),
                seasonTicketEventSessionUuids: [
                    this.eventSessionSettingsService.getEventSessionUuid()
                ]
            };
            await this.httpClient
                .request('delete', `/adminpanelapi/event-session-contents/remove`, {body: model}).toPromise();

            selectedDestEvents.forEach(destEvent => {
                this.destTable.splice(this.destTable.indexOf(destEvent), 1);
            });

            this.setDestPageData();
            this.sourceFilterSubject.next(this.sourceFilter);
        } catch (e) {
            this.error = e.error.message;
        }

        this.fullscreenLoaderService.close();
    }

    public moveToDestDisabled(): boolean {
        return !this.sourceTable.find(el => el.selected);
    }

    public moveToSourceDisabled(): boolean {
        return !this.destTable.find(el => el.selected);
    }

    public next(): void {
        this.eventSessionSettingsService.setCurrentState(SettingsPopupState.TICKET_LAYOUTS);
    }
}
