import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {HttpClient} from '@angular/common/http';
import {TicketTemplate} from '../../shared/types/ticket-template';
import {AbstractComponent} from '../../shared/abstract.component';
import {Observable, of} from 'rxjs';
import {DeviceModel, DeviceType} from '../../shared/types/device-model';
import {catchError, tap} from 'rxjs/operators';

export enum CuTicketTemplatePopupResult {
    CANCEL,
    SAVE,
}

@Component({
    templateUrl: './cu-ticket-template-popup.component.html',
    styleUrls: ['./cu-ticket-template-popup.component.scss'],
})
export class CuTicketTemplatePopupComponent extends AbstractComponent implements OnInit {
    public form: FormGroup;

    public popupResult = CuTicketTemplatePopupResult;

    public error: string | null = null;

    public loading = true;

    public deviceTypeSelectOpened = false;
    public deviceModelSelectOpened = false;

    public deviceTypeOptions = Object.values(DeviceType);

    public deviceModels: DeviceModel[] = [];

    private allDeviceModels: DeviceModel[] = [];

    constructor(
        private readonly dialogRef: MatDialogRef<CuTicketTemplatePopupComponent>,
        private readonly fb: FormBuilder,
        private readonly httpClient: HttpClient,
        @Inject(MAT_DIALOG_DATA) public readonly ticketTemplate: TicketTemplate,
        private readonly cdr: ChangeDetectorRef,
    ) {
        super();

        this.form = this.fb.group({
            uuid: [null],
            name: ['', [Validators.required]],
            note: [''],
            deviceType: [null, [Validators.required]],
            deviceModel: [null, [Validators.required]],
        });
    }

    public ngOnInit(): void {
        this.loading = true;

        this.loadDeviceModels()
            .pipe(
                tap(() => {
                    this.initForm();

                    this.loading = false;
                }),
                this.untilComponentDestroyed(),
            )
            .subscribe();

        this.form.controls.deviceType.valueChanges
            .pipe(
                tap(deviceType => {
                    this.deviceModels = this.allDeviceModels.filter(deviceModel => deviceModel.deviceType === deviceType);
                }),
                this.untilComponentDestroyed(),
            )
            .subscribe();
    }

    public close(result: CuTicketTemplatePopupResult, data?: any): void {
        this.dialogRef.close({
            result,
            data
        });
    }

    public save(): void {
        const form = this.form.value;

        const model: TicketTemplate = {
            uuid: form.uuid,
            name: form.name,
            deviceModelUuid: form.deviceModel.uuid,
            printImageType: form.deviceType,
            note: form.note,
        };

        this.error = null;
        this.form.disable();

        const request$ = model.uuid ?
            this.httpClient.put<TicketTemplate>('/adminpanelapi/print-image/update', model)
            : this.httpClient.post<TicketTemplate>('/adminpanelapi/print-image/create', model);

        request$
            .pipe(
                tap((newModel) => {
                    this.close(CuTicketTemplatePopupResult.SAVE, newModel);
                }),
                catchError(error => {
                    this.error = error.error.message;
                    this.form.enable();
                    return of(error);
                }),
            )
            .subscribe();
    }

    private loadDeviceModels(): Observable<any> {
        return this.httpClient.get<DeviceModel[]>('/adminpanelapi/device-model/all')
            .pipe(
                tap(deviceModels => {
                    this.allDeviceModels = deviceModels;
                })
            );
    }

    private initForm(): void {
        if (!this.ticketTemplate) {
            return;
        }
        const deviceModel = this.allDeviceModels.find(dm => dm.uuid === this.ticketTemplate.deviceModelUuid);

        this.form.patchValue({
            name: this.ticketTemplate.name,
            note: this.ticketTemplate.note,
            deviceType: deviceModel?.deviceType || null,
            deviceModel
        });

        this.cdr.detectChanges();
    }
}
