import {AbstractComponent} from '../../shared/abstract.component';
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';

const FIELDS_MAP: Record<string, string> = {
    EVENT_CAPTION: 'Профессиональный Клуб 1 - Клуб 2',
    EVENT_TYPE_NAME: 'Домашнее мероприятие',
    ORDER_CLIENT_UUID_PHONE: '99999999999',
    LOCATION_SCHEME_NAME: 'Схема Общая',
    RECEIPT_REG_NUM: '99999',
    TICKET_DATETIME: '31.12.2021 15:00',
    ORDER_CLIENT_UUID_EMAIL: 'order@getseat.ru',
    SHIFT_USER_UUID_NAME: 'Кассир А',
    TICKET_TAX: '99',
    EVENT_TEAM_B_UUID_TEAM_NAME: 'Клуб 2',
    EVENT_SESSION_EVENT_SESSION_MATCHDAY_UUID_NAME: 'Полуфинал',
    TICKET_CLIENT_UUID_FIRST_NAME: 'Иван',
    TICKET_ORDER_SEAT_UUID_CATEGORY_UUID_NAME: 'Взрослый',
    RECEIPT_TYPE: 'Продан',
    EVENT_TEAM_A_UUID_TEAM_NAME: 'Клуб 1',
    TICKET_PRICE: '999,99',
    SHIFT_CASH_REGISTER_UUID_REG_NUM: '999',
    RECEIPT_PAYMENT_PAYMEAN: 'Наличный',
    TICKET_CLIENT_UUID_MIDDLE_NAME: 'Иванович',
    ORDER_CLIENT_UUID_LAST_NAME: 'Иванов',
    TICKET_CLIENT_UUID_PHONE: '99999999999',
    RECEIPT_DATETIME: '31.12.2021 15:00',
    EVENT_NAME: 'Клуб 1 - Клуб 2',
    ORDER_CLIENT_UUID_MIDDLE_NAME: 'Иванович',
    TICKET_TYPE: 'Активный',
    TICKET_CUSTOMER_UUID_NAME: 'Физ. лицо',
    EVENT_SESSION_DATE_END_EVENT_SESSION_DATE_BEGIN: '02:00',
    TICKET_CLIENT_UUID_LAST_NAME: 'Иванов',
    SHIFT_CASH_REGISTER_UUID_NAME: 'Касса 1 Стадиона',
    EVENT_TEAM_B_UUID_TEAM_CAPTION: 'Клуб 2',
    TICKET_CLIENT_UUID_EMAIL: 'order@getseat.ru',
    ORDER_CLIENT_UUID_FIRST_NAME: 'Иван',
    EVENT_SESSION_EVENT_SESSION_TYPE_NAME: 'Открытый Чемпионат',
    TICKET_ORDER_UUID_REG_NUM: '9999999',
    EVENT_SESSION_SEASON_UUID_NAME: 'Сезон 2021',
    EVENT_SESSION_DATE_BEGIN: '31.12.2021 15:00',
    TICKET_BARCODE: '99999999999999999',
    TICKET_ORDER_SEAT_UUID_ZONE_UUID_NAME: 'Первая зона',
    EVENT_TEAM_A_UUID_TEAM_CAPTION: 'Профессиональный Клуб 1',
    RECEIPT_SHIFT_UUID_REG_NUM: '99999',
    EVENT_SESSION_DATE_END: '31.12.2021 17:00',
    LOCATION_NAME: 'Стадион Первый'
};

@Component({
    templateUrl: './ticket-template-properties-preview-popup.component.html',
    styleUrls: ['./ticket-template-properties-preview-popup.component.scss'],
})
export class TicketTemplatePropertiesPreviewPopupComponent extends AbstractComponent implements OnInit {
    public form: FormGroup;

    constructor(
        private readonly dialogRef: MatDialogRef<TicketTemplatePropertiesPreviewPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly properties: Record<string, string>,
        private readonly fb: FormBuilder,
    ) {
        super();

        this.form = this.fb.group({
            fields: new FormArray([])
        });
    }

    public ngOnInit(): void {
        const fields = (this.form.controls.fields as FormArray);
        Object.keys(this.properties).forEach(key => {
            fields.push(this.fb.group({
                field: [key],
                value: [FIELDS_MAP[key]]
            }));
        });
    }

    public getControls(): FormGroup[] {
        return (this.form.controls.fields as FormArray).controls as FormGroup[];
    }

    public next(): void {
        const result: Record<string, string> = {};
        this.form.value.fields.forEach((field: any) => {
            result[field.field] = field.value;
        });
        this.dialogRef.close(result);
    }

    public cancel(): void {
        this.dialogRef.close();
    }
}
