<div class="popup-wrapper">
    <div class="popup-header">
        <div class="body-text-1" *ngIf="!ticketTemplate">Создать шаблон билета</div>
        <div class="body-text-1" *ngIf="ticketTemplate">Редактировать шаблон билета</div>
        <button
            mat-icon-button
            disableRipple
            type="button"
            class="button-icon"
            color="primary"
            (click)="close(popupResult.CANCEL)"
        >
            <mat-icon svgIcon="times"></mat-icon>
        </button>
    </div>
    <div class="popup-body" [formGroup]="form">
        <div class="popup-body-row">
            <mat-form-field class="input-field" color="primary">
                <mat-label>Название*</mat-label>
                <input type="text" autocomplete="off" matInput formControlName="name">
            </mat-form-field>
        </div>
        <div class="popup-body-row">
            <mat-form-field appearance="fill" color="primary" class="input-select"
                            [class.input-select__opened]="deviceTypeSelectOpened">
                <mat-label>Тип макета*</mat-label>
                <mat-icon svgIcon="dropdown-arrow" matSuffix></mat-icon>
                <mat-select (openedChange)="deviceTypeSelectOpened = $event" disableRipple
                            formControlName="deviceType" disableOptionCentering
                            [panelClass]="['dropdown-panel', 'dropdown-panel-advanced']">
                    <div appSimplebarScroller>
                        <mat-option *ngFor="let deviceType of deviceTypeOptions" [value]="deviceType">
                            {{deviceType}}
                        </mat-option>
                    </div>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="popup-body-row">
            <mat-form-field appearance="fill" color="primary" class="input-select"
                            [class.input-select__opened]="deviceModelSelectOpened">
                <mat-label>Модель устройства*</mat-label>
                <mat-icon svgIcon="dropdown-arrow" matSuffix></mat-icon>
                <mat-select (openedChange)="deviceModelSelectOpened = $event" disableRipple
                            formControlName="deviceModel" disableOptionCentering
                            [panelClass]="['dropdown-panel', 'dropdown-panel-advanced']">
                    <div appSimplebarScroller>
                        <mat-option *ngFor="let deviceModel of deviceModels" [value]="deviceModel">
                            {{deviceModel.name}}
                        </mat-option>
                    </div>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="popup-body-row">
            <mat-form-field class="input-field" color="primary">
                <mat-label>Примечание</mat-label>
                <input type="text" autocomplete="off" matInput formControlName="note">
            </mat-form-field>
        </div>
    </div>
    <div class="error-container" *ngIf="error">
        <inline-alert-component [text]="error"></inline-alert-component>
    </div>
    <div class="popup-footer">
        <button
            mat-raised-button
            disableRipple
            type="button"
            class="button-primary-filled button-primary-filled-secondary"
            color="primary"
            [disabled]="form.invalid || form.disabled"
            (click)="save()"
        >
            Сохранить
        </button>
    </div>
</div>

