import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {UiKitModule} from '../shared/ui-kit/ui-kit.module';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatListModule} from '@angular/material/list';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {UiKitComponentsModule} from '../shared/ui-kit/ui-kit-components.module';
import {AppHttpInterceptor} from './app.http-interceptor';
import {DefaultErrorPopupComponent} from './popups/default-error-popup/default-error-popup.component';
import { OpenSalesPopupComponent } from './popups/open-sales-popup/open-sales-popup.component';
import { EventSessionSettingsPopupComponent } from './popups/event-settings-popup/event-session-settings-popup.component';
import { EventTicketConsistComponent } from './popups/event-settings-popup/event-ticket-consist/event-ticket-consist.component';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {SeasonTicketConsistComponent} from './popups/event-settings-popup/season-ticket-consist/season-ticket-consist.component';
import { TicketLayoutsComponent } from './popups/event-settings-popup/ticket-layouts/ticket-layouts.component';
import { PriceRatesComponent } from './popups/event-settings-popup/price-rates/price-rates.component';
import { OpenSalesComponent } from './popups/event-settings-popup/open-sales/open-sales.component';
import {NgxMaskModule} from 'ngx-mask';
import {NumberFormatterModule} from './shared/directivtes/number-formatter/number-formatter.module';
import { CuEventPopupComponent } from './popups/cu-event-popup/cu-event-popup.component';
import {EventTypeCatalogModule} from './components/reference-book/event-type-catalog/event-type-catalog.module';
import {BaseCatalogModule} from './components/reference-book/base-catalog/base-catalog.module';
import {TeamCatalogModule} from './components/reference-book/team-catalog/team-catalog.module';
import { CuSubscriptionPopupComponent } from './popups/cu-subscription-popup/cu-subscription-popup.component';
import {SubscriptionTypeCatalogModule} from './components/reference-book/subscription-type-catalog/subscription-type-catalog.module';
import { CuEventSessionPopupComponent } from './popups/cu-event-session-popup/cu-event-session-popup.component';
import {SeasonCatalogModule} from './components/reference-book/season-catalog/season-catalog.module';
import {EventSessionTypeCatalogModule} from './components/reference-book/event-session-type-catalog/event-session-type-catalog.module';
import {MatchDayCatalogModule} from './components/reference-book/match-day-catalog/match-day-catalog.module';
import { CuSubscriptionSessionPopupComponent } from './popups/cu-subscription-session-popup/cu-subscription-session-popup.component';
import { SubscriptionSessionInfoComponent } from './popups/event-settings-popup/subscription-session-info/subscription-session-info.component';
import { CuBlankStorePopupComponent } from './popups/cu-blank-store-popup/cu-blank-store-popup.component';
import { EventSessionInfoComponent } from './popups/event-settings-popup/event-session-info/event-session-info.component';
import {CopyEventSessionPricesFromPopupComponent} from './popups/copy-event-session-prices-from-popup/copy-event-session-prices-from-popup.component';
import { CopyEventSessionPricesToPopupComponent } from './popups/copy-event-session-prices-to-popup/copy-event-session-prices-to-popup.component';
import { CopySubscriptionSessionPricesFromPopupComponent } from './popups/copy-subscription-session-prices-from-popup/copy-subscription-session-prices-from-popup.component';
import { CopySubscriptionSessionPricesToPopupComponent } from './popups/copy-subscription-session-prices-to-popup/copy-subscription-session-prices-to-popup.component';
import { ConfirmationPopupComponent } from './popups/confirmation-popup/confirmation-popup.component';
import { SaleLimitsComponent } from './popups/event-settings-popup/sale-limits/sale-limits.component';
import { AnnouncementComponent } from './popups/event-settings-popup/announcement/announcement.component';
import { ImagePickerPopupComponent } from './popups/image-picker-popup/image-picker-popup.component';
import {ResourceTypeCatalogModule} from './components/reference-book/resource-type-catalog/resource-type-catalog.module';
import {AddImageModule} from './components/add-image/add-image.module';
import { LockTimeChangePopupComponent } from './popups/lock-time-change-popup/lock-time-change-popup.component';
import { SeatSalesHistoryPopupComponent } from './popups/seat-sales-history-popup/seat-sales-history-popup.component';
import {
    GroupedPriceRatePopupComponent
} from "./popups/event-settings-popup/price-rates/grouped-price-rate-popup/grouped-price-rate-popup.component";
import {CuCompanyPopupComponent} from './popups/cu-company-popup/cu-company-popup.component';
import {CuContractPopupComponent} from './popups/cu-contract-popup/cu-contract-popup.component';
import {CreateClientPopupComponent} from './popups/create-client-popup/create-client-popup.component';
import {CuClientGroupPopupComponent} from './popups/cu-client-group-popup/cu-client-group-popup.component';
import {MatRadioModule} from '@angular/material/radio';
import {CuUserPopupComponent} from './popups/cu-user-popup/cu-user-popup.component';
import {ClientLimitsComponent} from './popups/event-settings-popup/client-limits/client-limits.component';
import {
    CuEventSessionClientLimitPopupComponent
} from './popups/cu-event-session-client-limit-popup/cu-event-session-client-limit-popup.component';
import {CuTicketTemplatePopupComponent} from './popups/cu-ticket-template-popup/cu-ticket-template-popup.component';
import {
    TicketTemplatePropertiesPreviewPopupComponent
} from './popups/ticket-template-properties-preview-popup/ticket-template-properties-preview-popup.component';

const MAT_MODULES = [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatListModule,
    MatTableModule,
    MatCheckboxModule,
    MatRadioModule,
];

const SHARED_MODULES = [
    ReactiveFormsModule,
    CommonModule,
    NumberFormatterModule
];

@NgModule({
    declarations: [
        AppComponent,
        DefaultErrorPopupComponent,
        OpenSalesPopupComponent,
        EventSessionSettingsPopupComponent,
        EventTicketConsistComponent,
        SeasonTicketConsistComponent,
        TicketLayoutsComponent,
        PriceRatesComponent,
        OpenSalesComponent,
        CuEventPopupComponent,
        CuSubscriptionPopupComponent,
        CuEventSessionPopupComponent,
        CuSubscriptionSessionPopupComponent,
        CuBlankStorePopupComponent,
        SubscriptionSessionInfoComponent,
        EventSessionInfoComponent,
        CopyEventSessionPricesFromPopupComponent,
        CopyEventSessionPricesToPopupComponent,
        CopySubscriptionSessionPricesFromPopupComponent,
        CopySubscriptionSessionPricesToPopupComponent,
        ConfirmationPopupComponent,
        SaleLimitsComponent,
        ClientLimitsComponent,
        AnnouncementComponent,
        ImagePickerPopupComponent,
        LockTimeChangePopupComponent,
        SeatSalesHistoryPopupComponent,
        GroupedPriceRatePopupComponent,
        CuCompanyPopupComponent,
        CuContractPopupComponent,
        CreateClientPopupComponent,
        CuClientGroupPopupComponent,
        CuUserPopupComponent,
        CuEventSessionClientLimitPopupComponent,
        CuTicketTemplatePopupComponent,
        TicketTemplatePropertiesPreviewPopupComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        UiKitModule,
        UiKitComponentsModule,
        NgxMaskModule.forRoot({
            thousandSeparator: ' ',
            decimalMarker: '.'
        }),
        EventTypeCatalogModule,
        SubscriptionTypeCatalogModule,
        TeamCatalogModule,
        BaseCatalogModule,
        SeasonCatalogModule,
        EventSessionTypeCatalogModule,
        MatchDayCatalogModule,
        ResourceTypeCatalogModule,
        AddImageModule
    ].concat(MAT_MODULES).concat(SHARED_MODULES),
    exports: [UiKitComponentsModule].concat(MAT_MODULES).concat(SHARED_MODULES),
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppHttpInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        DefaultErrorPopupComponent,
        OpenSalesPopupComponent,
        SeatSalesHistoryPopupComponent,
        EventSessionSettingsPopupComponent,
        CuEventPopupComponent,
        CuSubscriptionPopupComponent,
        CuEventSessionPopupComponent,
        CuSubscriptionSessionPopupComponent,
        ConfirmationPopupComponent
    ]
})
export class AppModule {
}
